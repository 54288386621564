.map {
  width: 100%;
  height: auto;
  background-color: rgb(245, 105, 151);
}

.text {
  max-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.text > img {
  width: 50%;
}

.text > h2 {
  font-size: 300%;
  align-self: center;
  padding: 5%;
  color: white;
}

.list > li {
  margin-left: -40px;
  margin-top: -15px;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-between;
  padding: 3%;
}

.he {
  background-color: rgb(240, 87, 138);
}

.she {
  background-color: rgb(224, 159, 170);
}

span {
  font-size: 25px;
  color: white;
}

a {
  color: white;
  font-size: 200%;
}
