.country {
  display: flex;
  flex-direction: row;
  background-color: rgb(245, 105, 151);
  width: 100%;
  max-height: 400px;
}

.country > img {
  width: 60%;
}

.country > h1 {
  width: 40%;
  align-items: center;
  padding: 10%;
  color: whitesmoke;
}

.brewery {
  width: 100%;
  background-color: rgb(240, 87, 138);
  border-radius: 5%;
}

.brewery > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5%;
}

.brewery > div > img {
  width: 70%;
}

.logo {
  font-size: 100%;
  color: white;
  padding-top: 10px;
}

.brewery > h1 {
  color: white;
  font-size: 100%;
}

@media only screen and (max-width: 992px) {
  .breweries {
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    background-color: rgb(248, 119, 162);
  }
}

@media only screen and (min-width: 992px) {
  .breweries {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 5px;
    background-color: rgb(247, 128, 168);
  }
}
